<template>
  <div>
    <h1>Add Student</h1>
    <a-row>
      <a-col :span="24">
        <div class="card-full">
          <div class="detail">
            <a-form @submit.prevent="add">
              <a-col :span="24">
                <a-form-item label="Kelas">
                  <a-select
                    size="large"
                    :style="{
                      width: isDesktop ? '1000px' : '100%',
                      height: '40px'
                    }"
                    @change="handleSelect"
                  >
                    <a-select-option
                      v-for="item in classes"
                      :key="item.id"
                      :value="item.id"
                    >{{item.tingkat}}-{{item.simbol}}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="Tahun Masuk">
                  <a-input v-model="newData.tahun_masuk" style="height: 40px" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="NIS">
                  <a-input v-model="newData.nis" style="height: 40px" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="Nama Lengkap">
                  <a-input v-model="newData.nama" style="height: 40px" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="Jenis Kelamin">
                  <a-radio-group v-model="newData.jenis_kelamin">
                    <a-radio value="Laki-Laki">Laki-Laki</a-radio>
                    <a-radio value="Perempuan">Perempuan</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item label="Default Password">
                  <a-input v-model="newData.nis" read-only style="height: 40px" />
                </a-form-item>
              </a-col>
              <a-button
                :loading="loadingAdd"
                type="primary"
                html-type="submit"
                size="large"
                block
              >Add Now</a-button>
            </a-form>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classes: [],
      newData: {
        id_kelas: '',
        tahun_masuk: '',
        nis: '',
        nama: '',
        jenis_kelamin: '',
      },
      loadingAdd: false,
    }
  },
  methods: {
    fetchDataKelas() {
      this.$store.dispatch('admin/FETCH_CLASS')
        .then(data => {
          this.classes = data
        })
    },
    handleSelect(value) {
      this.newData.id_kelas = value
    },
    add() {
      // console.log(this.newData)
      if (this.newData.id_kelas && this.newData.tahun_masuk && this.newData.nis && this.newData.nama) {
        this.loadingAdd = true
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to add this account ?</div>
          ),
          onOk: () => {
            this.$store.dispatch('admin/ADD_STUDENT', { data: this.newData })
              .then(res => {
                this.loadingAdd = false
                if (res === 200) {
                  this.$notification.success({
                    message: 'Success',
                    description:
                      'this account has been added',
                  })
                  this.$router.push({ name: 'Student Admin' })
                } else if (res === 'nis must be unique') {
                  this.$notification.error({
                    message: 'Error',
                    description:
                      'this nis has already taken',
                  })
                } else {
                  this.$notification.error({
                    message: 'Error',
                    description:
                      'this account has not been added',
                  })
                }
              })
          },
          onCancel: () => {
            this.loadingAdd = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: 'Add',
        })
      } else {
        this.$notification.error({
          message: 'Error',
          description:
            'You must fill kelas, nis and nama',
        })
      }
    },
  },
  created() {
    this.fetchDataKelas()
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style scoped>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 40px 20px 20px 20px;
}
</style>
